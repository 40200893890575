import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText } from 'reactstrap'
import SelectBase, {
  FocusEventHandler,
  InputActionMeta,
  MenuPosition
} from 'react-select'
import { Flag } from '../../Flag'
import Control from './Control'
import DropdownIndicator from './DropdownIndicator'
import ClearIndicator from './ClearIndicator'

export type SelectOptionType = {
  label: string
  value: string | number
  isoCode?: string
  selectedLabel?: string
  [key: string]: any
}

export interface SelectProps<T> extends UseControllerProps<T> {
  label: string
  disabled?: boolean
  value?: SelectOptionType
  onChange?: ((value: any, actionMeta: any) => void) &
    ((value: any, action: any) => void)
  /**
   * el método "onInputChange" permite capturar cualquier cambio en el Select (incluso tipeo de letras y filtrado), a diferencia del "onChange" que se dispara después de seleccionar una de las opciones del select
   */
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void
  onBlur?: FocusEventHandler
  /**
   * Para agregar banderas tienes que agregar la propiedad isoCode en cada opción del select
   */
  options: SelectOptionType[]
  placeholder?: string
  errorText?: string
  /**
   * Utilizado para indicar si se mostrará el icono "X" para remover la opción seleccionada
   */
  isClearable?: boolean
  /**
   * Utilizado para indicar si se podrá escribir texto para buscar entre las opciones disponibles
   */
  isSearchable?: boolean
  /**
   * Mensaje que aparecerá cuando se abre el select y no hay opciones disponibles
   * ó cuando no se encuentran resultados al filtrar las opciones con texto de búsqueda
   */
  noOptionsMessage?: string
  menuPosition?: MenuPosition
  /**
   * Cuando menuPosition es "fixed" esta propiedad debería ser true
   * por problema para actualizar la posición al hacer scroll
   * https://github.com/JedWatson/react-select/issues/3734
   */
  menuShouldBlockScroll?: boolean
}

export const Select = <T extends FieldValues>(props: SelectProps<T>) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    label,
    disabled,
    errorText,
    options,
    placeholder,
    isClearable,
    isSearchable,
    noOptionsMessage,
    onInputChange,
    menuPosition = 'absolute',
    menuShouldBlockScroll = false
  } = props

  const { name, value, onChange, onBlur } = controlledProps
    ? controlledProps
    : props
  const ref = controlledProps?.ref

  return (
    <FormGroup>
      <div className='g-select-container'>
        <SelectBase
          id={name}
          name={name}
          label={label}
          className='g-select-base'
          classNamePrefix='g-select'
          components={{ Control, DropdownIndicator, ClearIndicator }}
          options={options}
          isDisabled={disabled}
          value={value}
          onChange={onChange}
          onInputChange={onInputChange}
          onBlur={onBlur}
          ref={ref}
          blurInputOnSelect={true}
          formatOptionLabel={(option, labelMeta) => {
            // Formato de opción en el menú
            if (labelMeta.context === 'menu' && option?.isoCode) {
              return (
                <div className='g-contain-option'>
                  <div className='g-flag-container'>
                    <Flag code={option.isoCode} size={14} />
                  </div>
                  <span>{option.label}</span>
                </div>
              )
            } else {
              return option.selectedLabel ? option.selectedLabel : option.label
            }
          }}
          placeholder={placeholder}
          isClearable={isClearable}
          isSearchable={isSearchable}
          noOptionsMessage={() => noOptionsMessage || ''}
          menuPosition={menuPosition}
          menuShouldBlockScroll={menuShouldBlockScroll}
        />

        <FormText className='g-input-error' color='danger'>
          {errorText || controlledFieldState?.error?.message || ''}
        </FormText>
      </div>
    </FormGroup>
  )
}

Select.defaultProps = {
  disabled: false,
  placeholder: 'Seleccione una opción',
  isClearable: true,
  isSearchable: true,
  noOptionsMessage: 'Sin resultados',
  menuPosition: 'absolute',
  menuShouldBlockScroll: false
}
