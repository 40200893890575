import React from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { FormGroup, FormText, Label } from 'reactstrap'
import SelectBase, {
  components,
  FocusEventHandler,
  MenuPosition
} from 'react-select'
import { IconColorType, Icon, IconNameType } from '../../Icon'

export type OutlinedSelectOptionType = {
  icon?: {
    name: IconNameType
    color: IconColorType
  }
  label: string
  value: string | number
  selectedLabel?: string
  [key: string]: any
}

export interface OutlinedSelectProps<T> extends UseControllerProps<T> {
  label?: string
  disabled?: boolean
  value?: OutlinedSelectOptionType
  onChange?: ((value: any, actionMeta: any) => void) &
    ((value: any, action: any) => void)
  onBlur?: FocusEventHandler
  /**
   * Para agregar un icono tienes que agregar el objeto icon en cada opción del select
   */
  options: OutlinedSelectOptionType[]
  placeholder?: string
  errorText?: string
  /**
   * Utilizado para indicar si se mostrará el icono "X" para remover la opción seleccionada
   */
  isClearable?: boolean
  /**
   * Utilizado para indicar si se podrá escribir texto para buscar entre las opciones disponibles
   */
  isSearchable?: boolean
  /**
   * Mensaje que aparecerá cuando se abre el select y no hay opciones disponibles
   * ó cuando no se encuentran resultados al filtrar las opciones con texto de búsqueda
   */
  noOptionsMessage?: string
  menuPosition?: MenuPosition
  /**
   * Cuando menuPosition es "fixed" esta propiedad debería ser true
   * por problema para actualizar la posición al hacer scroll
   * https://github.com/JedWatson/react-select/issues/3734
   */
  menuShouldBlockScroll?: boolean
}

export const OutlinedSelect = <T extends FieldValues>(
  props: OutlinedSelectProps<T>
) => {
  let controlledProps
  let controlledFieldState
  if (props.control) {
    const { field, fieldState } = useController<T>(props)
    controlledProps = field
    controlledFieldState = fieldState
  }
  const {
    label,
    disabled,
    errorText,
    options,
    placeholder,
    isClearable,
    isSearchable,
    noOptionsMessage,
    menuPosition = 'absolute',
    menuShouldBlockScroll = false
  } = props

  const { name, value, onChange, onBlur } = controlledProps
    ? controlledProps
    : props
  const ref = controlledProps?.ref

  return (
    <FormGroup>
      {label && (
        <Label for={name} className='g-outlined-select-label'>
          {label.toUpperCase()}
        </Label>
      )}
      <SelectBase
        id={name}
        name={name}
        className='g-outlined-select'
        classNamePrefix='g-outlined-select'
        components={{
          DropdownIndicator,
          ClearIndicator,
          SingleValue: ({ children, data, ...rest }) => (
            <React.Fragment>
              {data.icon && <Icon {...data.icon} className='mr-2' size={16} />}
              <components.SingleValue
                className={data.icon ? 'pl-4' : ''}
                data={data}
                {...rest}
              >
                {children}
              </components.SingleValue>
            </React.Fragment>
          )
        }}
        options={options}
        isDisabled={disabled}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        blurInputOnSelect={true}
        formatOptionLabel={(option) => {
          // Formato de opción en el menú
          return option.selectedLabel ? option.selectedLabel : option.label
        }}
        placeholder={placeholder}
        isClearable={isClearable}
        isSearchable={isSearchable}
        noOptionsMessage={() => noOptionsMessage || ''}
        menuPosition={menuPosition}
        menuShouldBlockScroll={menuShouldBlockScroll}
      />
      <FormText className='g-input-error' color='danger'>
        {errorText || controlledFieldState?.error?.message || ''}
      </FormText>
    </FormGroup>
  )
}

const DropdownIndicator = () => {
  return (
    <div className='g-outlined-select__indicator g-outlined-select__arrow-indicator'>
      <Icon name='chevron_down' size='14px' />
    </div>
  )
}

const ClearIndicator = ({ innerProps }: any) => {
  return (
    <div
      className='g-outlined-select__indicator g-outlined-select__clear-indicator'
      onMouseDown={innerProps.onMouseDown}
    >
      <Icon name='close' size='12px' />
    </div>
  )
}

OutlinedSelect.defaultProps = {
  disabled: false,
  placeholder: 'Seleccione una opción',
  isClearable: true,
  isSearchable: true,
  noOptionsMessage: 'Sin resultados',
  menuPosition: 'absolute',
  menuShouldBlockScroll: false
}
